import styled from '@emotion/styled'
import { fontFamilies } from '../../theme/typography'

export const ThankYouCardContainer = styled.div(props => ({
  flexDirection: 'column',
  display: 'flex-end',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 'auto',
  backgroundColor: props.theme.colors.gray.dark,
  padding: `${props.theme.spacing.m}px`,
  borderRadius: `${props.theme.spacing.m}px`,
}))

export const ThankYouCardHeader = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  fontSize: props.theme.typography.fontSizes.l,
  color: props.theme.colors.white.default,
  fontWeight: props.theme.typography.fontWeights.bold,
  fontFamily: fontFamilies.stratum2Bold,
  textTransform: 'uppercase',
  paddingTop: `${props.theme.spacing.m}px`,
}))

export const ThankYouCardBody = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  color: props.theme.colors.white.default,
  fontFamily: fontFamilies.openSansRegular,
  paddingTop: `${props.theme.spacing.m}px`,
}))

export const ThankYouCardFooter = styled.div(props => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  paddingTop: `${props.theme.spacing.m}px`,
  borderRadius: `${props.theme.spacing.m}px`,
}))
