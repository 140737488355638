import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { afreServiceUrl } from '../../api/afre.service'
import config from '../../config'
import { selectAuthUserToken } from '../../redux/selectors/auth.selectors'
import { getWeekGroupRows } from '../redux/ducks/opa.duck'
import { axiosConfig, queryStringURL } from '../../api/util'

const get = (url, queryParams = {}, authToken = undefined) => {
  return axios.get(queryStringURL(url, queryParams), axiosConfig(authToken))
}

const put = (url, data, queryParams = {}, authToken = undefined) => {
  return axios.put(queryStringURL(url, queryParams), data, axiosConfig(authToken))
}

const post = (url, data, queryParams = {}, authToken = undefined) => {
  return axios.post(queryStringURL(url, queryParams), data, axiosConfig(authToken))
}

const httpDelete = (url, queryParams = {}, authToken = undefined) => {
  return axios.delete(queryStringURL(url, queryParams), axiosConfig(authToken))
}

export const getWeekGroups = async token => {
  const response = await get(`${afreServiceUrl}/opa/weekGroups`, {}, token)
  return response.data.data
}

export const findUserOrders = async (ssn, token) => {
  const response = await post(`${afreServiceUrl}/opa/userOrders`, { ssn }, {}, token)
  return response.data.data
}

export const useWeekGroupUpload = () => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['uploadWeekGroup', token], async ({ specialOrderNumber, weekGroup, selectedFile }) => {
    const formData = new FormData()
    formData.append('files', selectedFile)
    formData.append('specialOrderNumber', specialOrderNumber)
    formData.append('weekGroup', weekGroup)

    return await axios.post(`${config.apiUrl}/opa/weekGroups`, formData, {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
    })
  })
}
export const useMilpdsPull = () => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['pullMilpdsOrders', token], async ({ milpdsSpecialOrderNumber, milpdsWeekGroup }) => {
    return await post(`${config.apiUrl}/opa/pullOrders`, { specialOrderNumber: milpdsSpecialOrderNumber, weekGroup: milpdsWeekGroup }, {}, token)
  })
}

export const useUpdateWeekGroupRows = () => {
  const token = useSelector(selectAuthUserToken)
  const dispatch = useDispatch()
  let weekGroupIdCopy = ''
  return useMutation(['updateWeekGroupRows', token], async ({ updatedOrderData, weekGroupId }) => {
    weekGroupIdCopy = weekGroupId
    return await put(`${afreServiceUrl}/opa/weekGroups/${weekGroupId}`, { updatedOrderData }, {}, token)
  }, { onSuccess: () => {
    dispatch(getWeekGroupRows.request({ weekGroup: weekGroupIdCopy }))
  },
  })
}

export const useGetMtiEmails = () => {
  const token = useSelector(selectAuthUserToken)
  return useQuery(['getMtiEmails', token], async () => {
    const response = await get(`${afreServiceUrl}/opa/templates/mtiEmails`, {}, token)
    return response.data.data
  }, { retry: false, cacheTime: 0 })
}

export const useDeleteMtiEmail = queryClient => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['deleteMtiEmail', token], async ({ emailId }) => {
    return await httpDelete(`${afreServiceUrl}/opa/templates/mtiEmails/${emailId}`, {}, token)
  }, { onSuccess: () => {
    queryClient.invalidateQueries(['getMtiEmails', token])
  },
  })
}

export const useAddMtiEmail = queryClient => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['addMtiEmail', token], async ({ updatedEmailData }) => {
    return await post(`${afreServiceUrl}/opa/templates/mtiEmails`, { value: updatedEmailData }, {}, token)
  }, { onSuccess: () => {
    queryClient.invalidateQueries(['getMtiEmails', token])
  },
  })
}

export const useGetWeekGroupRow = () => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['getWeekGroupRow', token], async ({ weekGroupId, dataSortValue, fullOrderData }) => {
    return await get(`${afreServiceUrl}/opa/weekGroups/${weekGroupId}/${dataSortValue}`, { fullOrderData }, token)
  }, { retry: false, cacheTime: 0 })
}

export const useGetWeekGroups = () => {
  const token = useSelector(selectAuthUserToken)
  return useMutation(['weekGroups', token], async () => {
    const response = await get(`${afreServiceUrl}/opa/weekGroups`, {}, token)
    return response.data.data
  })
}
