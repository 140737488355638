import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import spacing from '../../../theme/spacing'
import { ARMS_STATUS_ICONS, canArmsStatusBeEdited } from '../../pages/armsStatusProperties'
import { NegativeIcon } from '../../pages/Opa.styles'
import { ArmsStatusIcon, DynamicTableCell } from '../../pages/ProcessWeekGroupPage.styles'
import { ARMS_STATUS } from '../../redux/ducks/opa.duck'

const getCellValue = (weekGroupRow, dataKey) => {
  if (dataKey === 'isSpaceForce') {
    return weekGroupRow[dataKey] ? 'YES' : 'NO'
  }
  return weekGroupRow[dataKey]
}

const getArmsStatusIcon = weekGroupRow => {
  const { armsStatus } = weekGroupRow
  return armsStatus ? ARMS_STATUS_ICONS[armsStatus] : ARMS_STATUS_ICONS[ARMS_STATUS.NOT_READY]
}

const OpaOrderTableRow = props => {
  const {
    setSelectedWeekGroupRow,
    setEditOrdersModalOpen,
    setEditModalOpen,
    setWeekGroupDataToDelete,
    setDeleteModalText,
    setDeleteModalOpen,
  } = props.setterFunctions
  return (
    <Table.Row data-testid='order-row'>
      {
        props.weekGroupRowProperties.map(weekGroupRowProperty => {
          const dataKey = weekGroupRowProperty.key
          if (dataKey === 'editOrders') {
            return (
              <Table.Cell data-testid='edit-view-full-order-table-cell' key={ dataKey } style={ { textAlign: 'center' } }>
                <Icon
                  data-testid='edit-view-full-order-icon'
                  title={ `${canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) ? 'Edit' : 'View'} Full Order` }
                  name={ canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) ? 'edit' : 'eye' }
                  link
                  size='large'
                  onClick={ () => {
                    setSelectedWeekGroupRow(props.weekGroupRow)
                    setEditOrdersModalOpen(true)
                  } }
                />
              </Table.Cell>
            )
          } else if (dataKey === 'editDeleteWeekGroupRow') {
            return (
              <Table.Cell key={ dataKey }>
                <div style={ { display: 'flex', justifyContent: 'space-between', minWidth: spacing.xl } }>
                  <Icon
                    link={ canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) }
                    data-testid='edit-order-icon'
                    title='Edit Row'
                    name='pencil'
                    size='large'
                    disabled={ !canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) }
                    onClick={ () => {
                      setSelectedWeekGroupRow(props.weekGroupRow)
                      setEditModalOpen(true)
                    } }
                  />
                  <NegativeIcon
                    link={ canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) }
                    data-testid='delete-order-icon'
                    title='Delete Row'
                    name='trash alternate outline'
                    size='large'
                    disabled={ !canArmsStatusBeEdited(props.weekGroupRow, props.showArmsFeatures) }
                    onClick={ () => {
                      setWeekGroupDataToDelete(props.weekGroupRow)
                      setDeleteModalText(`Are you sure you want to delete ${props.weekGroupRow.name}?`)
                      setDeleteModalOpen(true)
                    } }
                  />
                </div>
              </Table.Cell>
            )
          } else if (dataKey === 'armsStatus') {
            const item = getArmsStatusIcon(props.weekGroupRow)
            const { icon, color } = item
            const status = props.weekGroupRow && props.weekGroupRow.armsStatus ? props.weekGroupRow.armsStatus : ARMS_STATUS.NOT_READY
            return props.showArmsFeatures ? (
              <Table.Cell data-testid='arms-status-table-cell' key={ dataKey } style={ { textAlign: 'center', color } }>
                <ArmsStatusIcon
                  title={ status }
                  name={ icon }
                  iconcolor={ color }
                  size='large'
                />
              </Table.Cell>
            ) : null
          } else {
            return (
              <DynamicTableCell
                data-testid='table-cell'
                key={ dataKey }
                errors={ props.weekGroupRowErrors[weekGroupRowProperty.key] }
                title={
                  props.weekGroupRowErrors[weekGroupRowProperty.key] ?
                    props.weekGroupRowErrors[weekGroupRowProperty.key].toUpperCase() : undefined
                }>
                { getCellValue(props.weekGroupRow, weekGroupRowProperty.key) }
              </DynamicTableCell>
            )
          }
        })
      }
    </Table.Row>
  )
}

OpaOrderTableRow.propTypes = {
  weekGroupRow: PropTypes.object,
  weekGroupRowProperties: PropTypes.array,
  setterFunctions: PropTypes.object,
  showArmsFeatures: PropTypes.bool,
  weekGroupRowErrors: PropTypes.object,
}

export default OpaOrderTableRow
