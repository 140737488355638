import jwtDecode from 'jwt-decode'
import { createSelector } from 'reselect'
import { OPA_ROLES, ROLES } from '../../config'
import { authActions } from '../constants/auth.constants'
import { createErrorSelector, createIsLoadingSelector } from './common.selectors'

const assertTokenAlive = decoded => {
  const now = Date.now().valueOf() / 1000
  if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
    throw new Error(`token expired: ${JSON.stringify(decoded)}`)
  }
  if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
    throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
  }
}

export const isTokenValid = token => {
  try {
    assertTokenAlive(jwtDecode(token))
  } catch (err) {
    return false
  }
  return true
}

export const selectAuth = ({ auth }) => auth

export const selectRefreshToken = createSelector(
  [selectAuth],
  auth => auth.refreshToken
)

export const selectIsRefreshTokenValid = createSelector(
  [selectAuth],
  auth => !!auth.refreshToken && isTokenValid(auth.refreshToken)
)

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  auth => !!auth.token
)

export const selectAuthUserInfo = createSelector(
  [selectAuth],
  auth => auth.userInfo
)

export const selectAuthUserToken = createSelector(
  [selectAuth],
  auth => auth.token
)

export const selectCreateAccountErrors = createErrorSelector(authActions.CREATE_ACCOUNT)
export const selectLoginEmailErrors = createErrorSelector(authActions.LOGIN_EMAIL)
export const selectLoginEmailLoading = createIsLoadingSelector(authActions.LOGIN_EMAIL)
export const selectInitAuthLoading = createIsLoadingSelector(authActions.INIT_AUTH)

export const selectAuthUserId = createSelector(
  [selectAuth],
  auth => {
    const token = auth.token
    const authUser = token && jwtDecode(token)
    return authUser && authUser.id
  }
)

export const selectAuthUser = createSelector(
  [selectAuth],
  auth => {
    const token = auth.token
    return token && jwtDecode(token)
  }
)

export const selectAuthUserRoles = createSelector(
  [selectAuthUser],
  user => {
    return user ? user.roles : []
  }
)

export const selectIsAdmin = createSelector(
  [selectAuthUser],
  authUser => {
    return !!authUser && !!authUser.roles && (authUser.roles.includes(ROLES.ADMIN) || authUser.roles.includes(ROLES.MASTER_ADMIN))
  }
)

export const selectIsMasterAdmin = createSelector(
  [selectAuthUser],
  authUser => {
    return !!authUser && !!authUser.roles && authUser.roles.includes(ROLES.MASTER_ADMIN)
  }
)

export const selectHasMassAlerts = createSelector(
  [selectAuthUser],
  authUser => {
    return !!authUser && !!authUser.roles && authUser.roles.includes(ROLES.MASS_ALERTS)
  }
)

export const selectIsOpaAdmin = createSelector(
  [selectAuthUser],
  authUser => {
    return !!authUser && !!authUser.roles && authUser.roles.includes(OPA_ROLES.ADMIN)
  }
)

export const selectIsOpaMtl = createSelector(
  [selectAuthUser],
  authUser => {
    return !!authUser && authUser.roles && authUser.roles.includes(OPA_ROLES.MTL)
  }
)

export const selectAuthUserTokenData = createSelector(
  [selectAuthUserToken],
  token => token && jwtDecode(token)
)

export const selectRefreshTokenData = createSelector(
  [selectRefreshToken],
  token => token && jwtDecode(token)
)
