import React from 'react'
import { useSelector } from 'react-redux'
import { Field, getFormSyncErrors, hasSubmitFailed } from 'redux-form'
import {
  Section,
  TwoColumnGrid,
  SectionTitle,
  StyledFieldset,
  StyledLegend,
  FieldsetContent
} from '../afcepLandingPage/AfcepLandingPage.styles'
import { AFCEP_FORM_NAME, BIRTH_SEX, GENDER, US_CITIZENSHIP_TYPE } from '../afcep.constants'
import { HISPANIC_INDICATOR, RACE } from '../../lead/constants'
import { FormItem } from '../../../forms/form.styles'
import DropdownField from '../../../components/Forms/DropdownField'
import { required } from '../../../forms/validation'
import theme from '../../../theme'
import { Checkbox } from '../../../components/Forms/Checkbox'

export const Demographics = () => {
  const formSyncErrors = useSelector(getFormSyncErrors(AFCEP_FORM_NAME))
  const submitFailed = useSelector(hasSubmitFailed(AFCEP_FORM_NAME))

  const renderCheckboxes = item => {
    return (
      <FormItem key={ item.text } width={ { base: 'auto' } }>
        <Field
          name='race'
          component={ ({ input }) => (
            <Checkbox
              key={ item.text }
              label={ item.text }
              value={ item.key }
              data-testid={ item.text }
              checked={ input.value.indexOf(item.key) !== -1 }
              style={ { display: 'block', fontSize: theme.typography.fontSizes.m } }
              onChange={ (_e, data) => {
                let newValue = input.value

                if (data.checked) {
                  newValue = newValue + item.key
                } else {
                  newValue = newValue.replace(item.key, '')
                }

                return input.onChange(newValue)
              } }
            />
          ) }
        />
      </FormItem>
    )
  }

  return (
    <Section>
      <SectionTitle data-testid='demographics-section-title'>Demographics</SectionTitle>
      <TwoColumnGrid>
        <FormItem width={ { base: 'auto' } } data-testid='birth-sex-field'>
          <Field
            id='birthSex'
            name='birthSex'
            label='Birth Sex*'
            data-testid='birthSex'
            placeholder='Select'
            options={ BIRTH_SEX }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
        <FormItem width={ { base: 'auto' } } data-testid='preferred-gender-field'>
          <Field
            id='preferredGender'
            name='preferredGender'
            label='Preferred Gender*'
            data-testid='preferredGender'
            placeholder='Select'
            options={ GENDER }
            component={ DropdownField }
            validate={ required }
          />
        </FormItem>
      </TwoColumnGrid>
      <FormItem width={ { base: 'auto' } } data-testid='us-citizen-type-field'>
        <Field
          id='citizenshipStatus'
          name='citizenshipStatus'
          label='US Citizenship Type*'
          data-testid='citizenshipStatus'
          placeholder='Select'
          options={ US_CITIZENSHIP_TYPE }
          component={ DropdownField }
          validate={ required }
        />
      </FormItem>
      <StyledFieldset>
        <StyledLegend className={ formSyncErrors.race && submitFailed ? 'hasError' : null } data-testid='racial-category-field'>
          Racial Category* <span>(Select one or more)</span>
        </StyledLegend>
        <FieldsetContent>
          { RACE.map(item => renderCheckboxes(item)) }
        </FieldsetContent>
      </StyledFieldset>
      <FormItem width={ { base: 'auto' } } data-testid='ethnicity-field'>
        <Field
          id='ethnicity'
          name='ethnicity'
          label='Ethnicity*'
          data-testid='ethnicity'
          placeholder='Select'
          options={ HISPANIC_INDICATOR }
          component={ DropdownField }
          validate={ required }
        />
      </FormItem>
    </Section>
  )
}
