import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { loginEmail } from '../../redux/sagas/auth.sagas'
import Button from '../../components/Button/Button'
import { FormSectionContainer, FormItem, FormSubmitButtonsContainer } from '../form.styles'
import InputField from '../../components/Forms/InputField'
import { required, email, minLength8 } from '../validation'
import FormSectionTitle from '../../components/Forms/FormSectionTitle'
import { selectLoginEmailErrors } from '../../redux/selectors/auth.selectors'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'

class LoginForm extends Component {

  handleLoginSubmit = values => {
    const { onLoginEmail, routedFrom } = this.props
    onLoginEmail(values, routedFrom)
  }

  render() {
    const { handleSubmit, loginErrors } = this.props
    return (
      <form onSubmit={ handleSubmit(this.handleLoginSubmit) }>
        <FormSectionTitle
          title='Login'
          data-testid='login-title'
        />
        <FormSectionContainer>
          <FormItem width={ { base: '100%' } } data-testid='login-email-field'>
            <Field
              id='email'
              name='email'
              label='Email'
              component={ InputField }
              type='text'
              validate={ [required, email] }
              maxLength={ MAX_LENGTH.STANDARD }
            />
          </FormItem>
        </FormSectionContainer>
        <FormSectionContainer>
          <FormItem width={ { base: '100%' } } data-testid='login-password-field'>
            <Field
              id='password'
              name='password'
              label='Password'
              component={ InputField }
              type='password'
              validate={ [required, minLength8] }
              maxLength={ MAX_LENGTH.STANDARD }
            />
          </FormItem>
        </FormSectionContainer>
        { loginErrors &&
          <div data-testid='invalid-login-message'>{ loginErrors.message || 'Invalid email/password' }</div>
        }
        <FormSubmitButtonsContainer style={ { marginLeft: 0, marginRight: 0 } }>
          <Button type='submit' data-testid='login-button'>Login</Button>
        </FormSubmitButtonsContainer>
      </form>
    )
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  onLoginEmail: PropTypes.func,
  routedFrom: PropTypes.object,
  loginErrors: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    loginErrors: selectLoginEmailErrors(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    onLoginEmail: (values, routedFrom) => dispatch(loginEmail.request({ values, routedFrom })),
  }
}

const ConnectedLoginForm = connect(mapStateToProps, mapActionsToProps)(LoginForm)

export default withRouter(reduxForm({
  form: 'login',
})(ConnectedLoginForm))
