/* eslint-disable no-console */
import React from 'react'
import { Tooltip } from 'react-tooltip'
import { Field, FormSection } from 'redux-form'
import InputField from '../../components/Forms/InputField'
import { Info } from '../../icons'
import { LeadDivider } from '../../pages/profile/profile.page.styles'
import { MAX_LENGTH } from '../../redux/constants/form.values.constants'
import colors from '../../theme/colors'
import spacing from '../../theme/spacing'
import {
  FormItem,
  FormLink,
  FormSectionContainer,
  FormSectionDescription,
  FormSectionTitle,
  FormSectionTitleRow,
  FormSectionWrapper
} from '../form.styles'
import { dodid, email } from '../validation'

function ReferrerInfoSection({ unknownReferrer }) {

  const formSectionTitle = '(Airmen / Guardians only)'
  const dodiTooltipHTML = `
    <div>
      <p>The Department of Defense Identification<br />
      Number (DoD ID Number), is a 10 digit number<br />
      required for participation in the STARR program.
      </p>
    </div>
  `
  const cssEmailTooltipHTML = `
    <div>
      <p>The Commander's support staff email<br />
      (CSS email), is a unit-level staff<br />
      members email address that is<br />
      required for participation in the STARR program.
      </p>
    </div>
  `
  return (
    <FormSectionWrapper style={ { paddingBottom: spacing.m } }>
      <FormSectionTitleRow>
        <FormSectionTitle data-testid='form-section-title'>Referred By </FormSectionTitle>
        <FormSectionTitle data-testid='form-m-section-title' fontSize='m'>{ formSectionTitle }</FormSectionTitle>
      </FormSectionTitleRow>
      <FormSectionDescription data-testid='form-section-description'>
        { unknownReferrer ?
          'Complete each of the following fields with your referrer\'s information to give them credit.' :
          'Airmen and Guardians must complete each of the following fields to participate in the ' }
        { unknownReferrer ?
          <></> :
          <FormLink
            data-testid='form-link'
            rel='noopener noreferrer'
            target='_blank'
            href='https://www.af.mil/News/Article-Display/Article/3399745/air-force-space-force-to-offer-medals-promotions-for-recruiting-support/'>
            STARR program.
          </FormLink> }
      </FormSectionDescription>
      <FormSection name='referrerInfo'>
        <FormSectionContainer>
          <FormItem width={ { min: '210px', base: 'auto' } } data-testid='referrer-first-name'>
            <Field
              id='referrerFirstName'
              name='referrerFirstName'
              label='First Name'
              component={ InputField }
              type='text'
              maxLength={ MAX_LENGTH.STANDARD }
            />
          </FormItem>
          <FormItem width={ { min: '220px', base: 'auto' } } data-testid='referrer-last-name'>
            <Field
              id='referrerLastName'
              name='referrerLastName'
              label='Last Name'
              component={ InputField }
              type='text'
              initial='test'
              maxLength={ MAX_LENGTH.STANDARD }
            />
          </FormItem>
          <FormItem width={ { min: '220px', base: '100%' } } data-testid='referrer-email-address'>
            <Field
              id='referrerEmailAddress'
              name='referrerEmailAddress'
              label='Email (Must be .mil)'
              component={ InputField }
              validate={ [email] }
              type='text'
            />
          </FormItem>
          <FormItem style={ { flexGrow: 0, flexShrink: 0, width: '50%' } } width={ { min: '220px' } } data-testid='referrer-dod-id-number'>
            <Field
              id='referrerDodIdentification'
              name='referrerDodIdentification'
              label='DoD Id Number'
              component={ InputField }
              validate={ [dodid] }
              type='text'
              maxLength={ 10 }
              tooltip={
                <div
                  data-tooltip-id='referrerDodIdentification-tooltip'
                  data-tooltip-html={ dodiTooltipHTML }
                  data-tooltip-place='top'>
                  <Info fill={ colors.blue.default } stroke={ colors.blue.default } />
                </div>
              }
            />
            <Tooltip id='referrerDodIdentification-tooltip' openOnClick />
          </FormItem>
          <FormItem style={ { flexGrow: 0, flexShrink: 0, width: '50%' } } width={ { min: '220px' } } data-testid='referrer-css-email'>
            <Field
              id='referrerCssEmail'
              name='referrerCssEmail'
              label='Commander Support Staff Email'
              component={ InputField }
              validate={ [email] }
              type='text'
              tooltip={
                <div
                  data-tooltip-id='referrerCssEmail-tooltip'
                  data-tooltip-html={ cssEmailTooltipHTML }
                  data-tooltip-place='top'>
                  <Info fill={ colors.blue.default } stroke={ colors.blue.default } />
                </div>
              }
            />
            <Tooltip id='referrerCssEmail-tooltip' openOnClick />
          </FormItem>
        </FormSectionContainer>
      </FormSection>
      <LeadDivider style={ { marginLeft: spacing.s, marginRight: spacing.s, paddingTop: spacing.m } } />
    </FormSectionWrapper>
  )
}

export default ReferrerInfoSection
