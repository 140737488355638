import { LightPalette } from '../../theme'
import { ARMS_STATUS } from '../redux/ducks/opa.duck'

export const ARMS_STATUS_ICONS = {
  [ARMS_STATUS.NOT_READY]: {
    icon: 'stop circle outline',
    color: LightPalette.text.nonessential,
  },
  [ARMS_STATUS.READY]: {
    icon: 'send',
    color: LightPalette.text.action,
  },
  [ARMS_STATUS.FAILED]: {
    icon: 'times circle',
    color: LightPalette.text.negative,
  },
  [ARMS_STATUS.COMPLETE]: {
    icon: 'check circle',
    color: LightPalette.text.positive,
  },
}

export const canArmsStatusBeEdited = (row, showArmsFeatures) => {
  const editableStatuses = [ARMS_STATUS.NOT_READY, ARMS_STATUS.FAILED]
  return (row && showArmsFeatures) ? editableStatuses.includes(row.armsStatus) : true
}
