import { push, replace } from 'connected-react-router'
import { delay } from 'redux-saga'
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import afreService from '../../api/afre.service'
import { NAV_ROUTES } from '../../navigation/routes'
import {
  selectAuthUserRoles,
  selectAuthUserToken,
  selectAuthUserTokenData,
  selectIsRefreshTokenValid,
  selectRefreshToken
} from '../../redux/selectors/auth.selectors'
import {
  authActions,
  AUTH_TOKEN_FUTURE_CHECK_MS,
  AUTH_TOKEN_REFRESH_BUFFER_MS,
  COOKIE_REFRESH_TOKEN_KEY,
  COOKIE_TOKEN_KEY
} from '../constants/auth.constants'
import AsyncSaga, { runAsyncSaga } from './util/AsyncSaga'
import SafeSaga from './util/SafeSaga'
import { getInitialRouteByRole } from '../../navigation/routeUtil'

export const convertDaysToMilliseconds = dayValue => {
  return Math.ceil(dayValue * 24 * 60 * 60 * 1000)
}

function setCookie(cookieName, cookieValue, daysToExpire) {
  if (!daysToExpire) {
    daysToExpire = parseFloat(process.env.REACT_APP_SESSION_DURATION_IN_DAYS, 10) || 7
  }
  const d = new Date()
  d.setTime(d.getTime() + convertDaysToMilliseconds(daysToExpire))
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`
}

function getCookie(cookieName) {
  const name = `${cookieName}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieValues = decodedCookie.split(';')
  for (let i = 0; i < cookieValues.length; i++) {
    let cookieValue = cookieValues[i]
    while (cookieValue.charAt(0) === ' ') {
      cookieValue = cookieValue.substring(1)
    }
    if (cookieValue.indexOf(name) === 0) {
      return cookieValue.substring(name.length, cookieValue.length)
    }
  }
  return ''
}

export const initializeAuth = new AsyncSaga(authActions.INIT_AUTH, function* initializeAuthSaga() {
  const cookieRefreshToken = getCookie(COOKIE_REFRESH_TOKEN_KEY)

  const cookieToken = getCookie(COOKIE_TOKEN_KEY)
  if (cookieToken) {
    yield put(initializeAuth.success({ token: cookieToken, refreshToken: cookieRefreshToken }))
  }

  if (cookieRefreshToken) {
    // eslint-disable-next-line no-use-before-define
    yield call(runAsyncSaga, authActions.REFRESH_TOKEN)
  }

  return null
})

export const createAccount = new AsyncSaga(authActions.CREATE_ACCOUNT, function* createAccountSaga(action) {
  const response = yield call(afreService.createAccount, action.params)

  const token = response.data.data.token
  // setCookie(COOKIE_TOKEN_KEY, token)
  yield put(createAccount.success({ token }))
})
  .catch(function* createAccountCatch(error) {
    yield put(createAccount.error(error, false))
  })

function* refreshRouteSaga(route) {
  yield put(push(route))
  yield new Promise(resolve => setTimeout(resolve, 100))
  window.location.reload()
}

export const loginEmail = new AsyncSaga(authActions.LOGIN_EMAIL, function* loginEmailSaga(action) {
  const response = yield call(afreService.loginEmail, action.params.values)
  const token = response.data.data.token
  const authRefreshToken = response.data.data.refresh_token
  setCookie(COOKIE_TOKEN_KEY, token)
  setCookie(COOKIE_REFRESH_TOKEN_KEY, authRefreshToken)

  yield put(loginEmail.success({ token, refreshToken: authRefreshToken }))

  if (
    !action.params.routedFrom.pathname ||
    action.params.routedFrom.pathname === NAV_ROUTES.LOGIN_PAGE
  ) {
    const authUserRoles = yield select(selectAuthUserRoles)
    const initialRoute = getInitialRouteByRole(authUserRoles)
    yield call(refreshRouteSaga, initialRoute)
  } else {
    const { pathname, search } = action.params.routedFrom
    const route = search ? `${pathname}${search}` : pathname
    yield put(push(route))
  }
})
  .catch(function* loginEmailCatch() {
    yield put(loginEmail.error(new Error('Invalid email/password'), false))
  })

export const logout = new SafeSaga(authActions.LOGOUT, function* logoutSaga(action) {
  setCookie(COOKIE_TOKEN_KEY, undefined, -1)
  setCookie(COOKIE_REFRESH_TOKEN_KEY, undefined, -1)
  yield put(replace(action.params.redirectPage))
})

export const refreshToken = new AsyncSaga(authActions.REFRESH_TOKEN, function* refreshTokenSaga() {
  const isAuthenticated = yield select(selectIsRefreshTokenValid) // Still good, let's try and refresh
  const authRefreshToken = yield select(selectRefreshToken)
  const authToken = yield select(selectAuthUserToken)
  let shouldLogout = true

  if (isAuthenticated) {
    const response = yield call(afreService.refreshToken, authRefreshToken, authToken)

    if (response.data.data.refreshed === true) {
      yield put(refreshToken.success({ token: response.data.data.token }))
      setCookie(COOKIE_TOKEN_KEY, response.data.data.token)
      shouldLogout = false
    }
    if (shouldLogout) {
      yield put(refreshToken.error(new Error('Could not refresh token')))
      yield call(runAsyncSaga, authActions.LOGOUT)
    }
  }
}).catch(function* refreshTokenCatch(err) {
  yield put(refreshToken.error(err))
})

export function* watchAuthSaga() {
  while (true) {
    yield delay(AUTH_TOKEN_FUTURE_CHECK_MS)
    const token = yield select(selectAuthUserTokenData)
    const refresh = yield select(selectRefreshToken)
    try {
      const future = (Date.now().valueOf() + AUTH_TOKEN_REFRESH_BUFFER_MS) / 1000
      if (refresh && (!token || (token.exp && token.exp < future))) {
        yield call(runAsyncSaga, authActions.REFRESH_TOKEN)
      } else if (!refresh && token) {
        // eslint-disable-next-line no-console
        console.error('Session expired. Logging user out...')
        yield call(runAsyncSaga, authActions.LOGOUT)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error in watchAuthSaga', error)
    }
  }
}

export default [
  takeLatest(createAccount.REQUEST, createAccount.saga),
  takeLatest(loginEmail.REQUEST, loginEmail.saga),
  takeLatest(initializeAuth.REQUEST, initializeAuth.saga),
  takeLatest(refreshToken.REQUEST, refreshToken.saga),
  takeEvery(logout.REQUEST, logout.saga),
  fork(watchAuthSaga),
]
