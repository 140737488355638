import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { afreServiceUrl } from '../../api/afre.service'

export const useCheckIfEventIdValid = (eventType, eventId) => {
  return useQuery(['afcepEventIdValid', eventId], async () => {
    const response = await axios.get(`${afreServiceUrl}/user/afrissEvent/verify/${eventType}/${eventId}`)
    return response.data.data
  }, { retry: false, cacheTime: 0 })
}

export const useSaveAfcepRequest = () => {
  return useMutation(['saveAfcepRequest'], async formData => {
    const response = await axios.put(`${afreServiceUrl}/e2o`, formData)
    return response.data
  })
}
