import { call, put, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import immutable from 'seamless-immutable'
import afreService from '../../../api/afre.service'
import { analyticsActions } from '../../../redux/constants/analytics.constants'
import AsyncSaga, { runAsyncSaga } from '../../../redux/sagas/util/AsyncSaga'
import { downloadFile } from '../../../redux/sagas/util/fileUtils'
import { createErrorSelector, createIsLoadingSelector } from '../../../redux/selectors/common.selectors'

export const OPA_ACTIONS = {
  PROCESS_WEEK_GROUP_ROWS: 'PROCESS_WEEK_GROUP_ROWS',
  UPDATE_TEMPLATE_INFO: 'UPDATE_TEMPLATE_INFO',
  UPDATE_AFSC_PROPERTIES: 'UPDATE_AFSC_PROPERTIES',
  GET_AFSC_PROPERTIES: 'GET_AFSC_PROPERTIES',
  DELETE_AFSC_PROPERTIES: 'DELETE_AFSC_PROPERTIES',
  ADD_AFSC_PROPERTIES: 'ADD_AFSC_PROPERTIES',
  GET_CONDITIONALS: 'GET_CONDITIONALS',
  GET_VARIABLES: 'GET_VARIABLES',
  GET_TEMPLATE_SECTIONS: 'GET_TEMPLATE_SECTIONS',
  GET_TEMPLATE_SECTION: 'GET_TEMPLATE_SECTION',
  UPDATE_SECTION_VARIATIONS: 'UPDATE_SECTION_VARIATIONS',
  DELETE_VARIATION: 'DELETE_VARIATION',
  GET_WEEK_GROUPS: 'GET_WEEK_GROUPS',
  DELETE_WEEK_GROUP: 'DELETE_WEEK_GROUP',
  GET_WEEK_GROUP_ROWS: 'GET_WEEK_GROUP_ROWS',
  UPDATE_WEEK_GROUP_ROW: 'UPDATE_WEEK_GROUP_ROW',
  DELETE_WEEK_GROUP_ROW: 'DELTE_WEEK_GROUP_ROW',
  GET_USER_ORDERS: 'GET_USER_ORDERS',
}

export const ARMS_STATUS = {
  NOT_READY: 'NOT READY',
  READY: 'READY',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
}

export const MARITAL_STATUSES = ['S', 'M']
export const SPACE_FORCE_STATUSES = ['YES', 'NO']

const INITIAL_STATE = immutable({
  variables: [],
  templateSections: [],
  weekGroupOrders: [],
})

const sortLeastToGreatest = (a, b) => {
  if (a.sort < b.sort) {
    return -1
  }
  if (a.sort > b.sort) {
    return 1
  }
  return 0
}

export const selectAddingAfscPropertiesError = createErrorSelector(OPA_ACTIONS.ADD_AFSC_PROPERTIES)
export const selectUpdatingSectionVariationsError = createErrorSelector(OPA_ACTIONS.UPDATE_SECTION_VARIATIONS)
export const selectUpdatingVariableError = createErrorSelector(OPA_ACTIONS.UPDATE_TEMPLATE_INFO)
export const selectUpdatingWeekGroupRowError = createErrorSelector(OPA_ACTIONS.UPDATE_WEEK_GROUP_ROW)
export const selectWeekGroupRowsLoading = createIsLoadingSelector(OPA_ACTIONS.PROCESS_WEEK_GROUP_ROWS)
export const selectGettingTemplateInfo = createIsLoadingSelector(OPA_ACTIONS.GET_VARIABLES)
export const selectUpdatingTemplateInfo = createIsLoadingSelector(OPA_ACTIONS.UPDATE_TEMPLATE_INFO)
export const selectGettingAfscProperties = createIsLoadingSelector(OPA_ACTIONS.GET_AFSC_PROPERTIES)
export const selectUpdatingAfscProperties = createIsLoadingSelector(OPA_ACTIONS.UPDATE_AFSC_PROPERTIES)
export const selectDeletingAfscProperties = createIsLoadingSelector(OPA_ACTIONS.DELETE_AFSC_PROPERTIES)
export const selectAddingAfscProperties = createIsLoadingSelector(OPA_ACTIONS.ADD_AFSC_PROPERTIES)
export const selectGettingConditionals = createIsLoadingSelector(OPA_ACTIONS.GET_CONDITIONALS)
export const selectGettingTemplateSections = createIsLoadingSelector(OPA_ACTIONS.GET_TEMPLATE_SECTIONS)
export const selectGettingTemplateSection = createIsLoadingSelector(OPA_ACTIONS.GET_TEMPLATE_SECTION)
export const selectUpdatingSectionVariations = createIsLoadingSelector(OPA_ACTIONS.UPDATE_SECTION_VARIATIONS)
export const selectDeletingVariation = createIsLoadingSelector(OPA_ACTIONS.DELETE_VARIATION)
export const selectGettingWeekGroupRows = createIsLoadingSelector(OPA_ACTIONS.GET_WEEK_GROUP_ROWS)
export const selectGettingWeekGroups = createIsLoadingSelector(OPA_ACTIONS.GET_WEEK_GROUPS)
export const selectDeletingWeekGroup = createIsLoadingSelector(OPA_ACTIONS.DELETE_WEEK_GROUP)
export const selectDeletingWeekGroupRow = createIsLoadingSelector(OPA_ACTIONS.DELETE_WEEK_GROUP_ROW)
export const selectUpdatingWeekGroupRow = createIsLoadingSelector(OPA_ACTIONS.UPDATE_WEEK_GROUP_ROW)
export const selectGettingUserOrders = createIsLoadingSelector(OPA_ACTIONS.GET_USER_ORDERS)

export const selectOPA = ({ opa }) => opa
export const selectAfscPropertyList = createSelector([selectOPA], opaState => opaState.afscList)
export const selectDeleteAfscWarning = createSelector([selectOPA], opaState => opaState.deleteWarning)
export const selectConditionals = createSelector([selectOPA], opaState => opaState.conditionals)
export const selectVariables = createSelector([selectOPA], opaState => opaState.variables)
export const selectTemplateSections = createSelector([selectOPA], opaState => opaState.templateSections)
export const selectWeekGroupRows = createSelector([selectOPA], opaState => opaState.weekGroupRows)
export const selectWeekGroups = createSelector([selectOPA], opaState => opaState.weekGroups)

export const processWeekGroupRows = new AsyncSaga(OPA_ACTIONS.PROCESS_WEEK_GROUP_ROWS, function* processWeekGroupRowsSaga({ params }) {
  const response = yield call(
    afreService.processWeekGroupRows,
    { weekGroup: params.selectedWeekGroup, rowSortIds: params.rowSortIds, sendWeekGroupCodeEmail: params.sendWeekGroupCodeEmail }
  )
  const {
    data,
    headers: {
      'content-type': contentType,
      'content-disposition': contentDisposition,
    },
  } = response
  yield call(downloadFile, data, contentType, contentDisposition.match(/[A-Za-z0-9_:.-]+\.\w+/u)[0])
  yield call(runAsyncSaga, analyticsActions.LOG_EVENT, { type: 'processWeekGroupRows', label: 'Process Week Group Rows' })
  yield put(processWeekGroupRows.success())
}).catch(function* processWeekGroupRowsCatch(error) {
  yield put(processWeekGroupRows.error(error.response))
})

export const updateVariables = new AsyncSaga(OPA_ACTIONS.UPDATE_TEMPLATE_INFO, function* updateVariablesSaga({ params }) {
  const response = yield call(afreService.updateVariables, params)

  yield put(updateVariables.success({ variables: response.data.data }))
}).catch(function* updateVariablesCatch(error) {
  yield put(updateVariables.error(error.response))
})

export const addAfscProperties = new AsyncSaga(OPA_ACTIONS.ADD_AFSC_PROPERTIES, function* addAfscPropertiesSaga({ params }) {
  const response = yield call(afreService.addAfscProperties, params.newAfscProperties)
  const updatedAfscList = [...params.currentAfscList]
  updatedAfscList.push(response.data.data)
  updatedAfscList.sort(sortLeastToGreatest)
  yield put(addAfscProperties.success({
    afscList: updatedAfscList,
  }))
}).catch(function* addAfscPropertiesCatch(error) {
  yield put(addAfscProperties.error(error.response))
})

export const deleteAfscProperties = new AsyncSaga(OPA_ACTIONS.DELETE_AFSC_PROPERTIES, function* deleteAfscPropertiesSaga({ params }) {
  const response = yield call(afreService.deleteAfscProperties, params.afscToDelete)
  const updatedAfscList = [...params.currentAfscList]
  const oldIndex = updatedAfscList.indexOf(params.afscToDelete)
  updatedAfscList.splice(oldIndex, 1)

  yield put(deleteAfscProperties.success({ afscList: updatedAfscList, deleteWarning: response.data.data }))
}).catch(function* deleteAfscPropertiesCatch(error) {
  yield put(deleteAfscProperties.error(error.response))
})

export const updateAfscProperties = new AsyncSaga(OPA_ACTIONS.UPDATE_AFSC_PROPERTIES, function* updateAfscPropertiesSaga({ params }) {
  const response = yield call(afreService.updateAfscProperties, params.updatedAfsc)
  const updatedAfscList = [...params.currentAfscList]
  const oldIndex = updatedAfscList.indexOf(params.afscData)
  updatedAfscList.splice(oldIndex, 1, response.data.data)

  yield put(updateAfscProperties.success({ afscList: updatedAfscList }))
}).catch(function* updateAfscPropertiesCatch(error) {
  yield put(updateAfscProperties.error(error.response))
})

export const getAfscProperties = new AsyncSaga(OPA_ACTIONS.GET_AFSC_PROPERTIES, function* getAfscPropertiesSaga() {
  const response = yield call(afreService.getAfscProperties)

  yield put(getAfscProperties.success({
    afscList: response.data.data.sort(sortLeastToGreatest),
  }))
}).catch(function* getAfscPropertiesCatch(error) {
  yield put(getAfscProperties.error(error.response))
})

export const getConditionals = new AsyncSaga(OPA_ACTIONS.GET_CONDITIONALS, function* getConditionalsSaga() {
  const response = yield call(afreService.getConditionals)

  yield put(getConditionals.success({ conditionals: response.data.data }))
}).catch(function* getConditionalsCatch(error) {
  yield put(getConditionals.error(error.response))
})

export const getVariables = new AsyncSaga(OPA_ACTIONS.GET_VARIABLES, function* getVariablesSaga() {
  const response = yield call(afreService.getVariables)

  yield put(getVariables.success({ variables: response.data.data }))
}).catch(function* getVariablesCatch(error) {
  yield put(getVariables.error(error.response))
})

export const getTemplateSections = new AsyncSaga(OPA_ACTIONS.GET_TEMPLATE_SECTIONS, function* getTemplateSectionsSaga() {
  const response = yield call(afreService.getTemplateSections)

  yield put(getTemplateSections.success({ templateSections: response.data.data }))
}).catch(function* getTemplateSectionsCatch(error) {
  yield put(getTemplateSections.error(error.response))
})

export const getTemplateSection = new AsyncSaga(OPA_ACTIONS.GET_TEMPLATE_SECTION, function* getTemplateSectionSaga({ params }) {
  const response = yield call(afreService.getTemplateSection, params.templateData[params.activeSectionIndex])
  const updatedTemplateSections = [...params.templateData]
  updatedTemplateSections.splice(params.activeSectionIndex, 1, response.data.data)

  yield put(getTemplateSection.success({ templateSections: updatedTemplateSections }))
}).catch(function* getTemplateSectionCatch(error) {
  yield put(getTemplateSection.error(error.response))
})

export const updateSectionVariations = new AsyncSaga(OPA_ACTIONS.UPDATE_SECTION_VARIATIONS, function* updateSectionVariationsSaga({ params }) {
  const response = yield call(afreService.updateSectionVariations, params.templateData[params.activeSectionIndex])
  const updatedTemplateSections = [...params.templateData]
  updatedTemplateSections.splice(params.activeSectionIndex, 1, response.data.data)

  yield put(updateSectionVariations.success({ templateSections: updatedTemplateSections }))
}).catch(function* updateSectionVariationsCatch(error) {
  yield put(updateSectionVariations.error(error.response))
})

export const deleteVariation = new AsyncSaga(OPA_ACTIONS.DELETE_VARIATION, function* deleteVariationSaga({ params }) {
  yield call(afreService.deleteVariation, params.variationToDelete)
  const updatedTemplateSections = [...params.templateData]
  const sectionIndex = updatedTemplateSections.indexOf(params.section)
  const variationIndex = updatedTemplateSections[sectionIndex].variations.indexOf(params.variationToDelete)
  const variationsCopy = [...updatedTemplateSections[sectionIndex].variations]
  variationsCopy.splice(variationIndex, 1)
  updatedTemplateSections[sectionIndex] = { ...updatedTemplateSections[sectionIndex], variations: variationsCopy }

  yield put(deleteVariation.success({ templateSections: updatedTemplateSections }))
}).catch(function* deleteVariationCatch(error) {
  yield put(deleteVariation.error(error.response))
})

export const deleteWeekGroup = new AsyncSaga(OPA_ACTIONS.DELETE_WEEK_GROUP, function* deleteWeekGroupSaga({ params }) {
  const { sort } = params.weekGroupDataToDelete
  yield call(afreService.deleteWeekGroup, { weekGroupId: sort })
  const updatedWeekGroups = [...params.currentWeekGroups]
  const oldIndex = updatedWeekGroups.indexOf(params.weekGroupDataToDelete)
  updatedWeekGroups.splice(oldIndex, 1)

  yield put(deleteWeekGroup.success({ weekGroups: updatedWeekGroups }))
}).catch(function* deleteWeekGroupCatch(error) {
  yield put(deleteWeekGroup.error(error.response))
})

export const getWeekGroupRows = new AsyncSaga(OPA_ACTIONS.GET_WEEK_GROUP_ROWS, function* getWeekGroupRowsSaga({ params }) {
  const response = yield call(afreService.getWeekGroupRows, params.weekGroup)

  yield put(getWeekGroupRows.success({ weekGroupRows: response.data.data }))
}).catch(function* getWeekGroupRowsCatch(error) {
  yield put(getWeekGroupRows.error(error.response))
})

export const updateWeekGroupRow = new AsyncSaga(OPA_ACTIONS.UPDATE_WEEK_GROUP_ROW, function* updateWeekGroupRowSaga({ params }) {
  const response = yield call(
    afreService.updateWeekGroupRow,
    { weekGroupId: params.weekGroupId, sortId: params.sortId, weekGroupRow: params.updatedWeekGroupRowData }
  )
  const updatedWeekGroupRows = [...params.weekGroupRowList]
  updatedWeekGroupRows.splice(params.weekGroupRowIndex, 1, response.data.data)

  yield put(updateWeekGroupRow.success({ weekGroupRows: updatedWeekGroupRows }))
}).catch(function* updateWeekGroupRowCatch(error) {
  yield put(updateWeekGroupRow.error(error.response))
})

export const deleteWeekGroupRow = new AsyncSaga(OPA_ACTIONS.DELETE_WEEK_GROUP_ROW, function* deleteWeekGroupRowSaga({ params }) {
  const { id, sort } = params.weekGroupDataToDelete
  yield call(afreService.deleteWeekGroupRow, { weekGroupId: id, sortId: sort })
  const updatedWeekGroupRows = [...params.currentWeekGroupRows]
  const oldIndex = updatedWeekGroupRows.indexOf(params.weekGroupDataToDelete)
  updatedWeekGroupRows.splice(oldIndex, 1)

  yield put(deleteWeekGroupRow.success({ weekGroupRows: updatedWeekGroupRows }))
}).catch(function* deleteWeekGroupRowCatch(error) {
  yield put(deleteWeekGroupRow.error(error.response))
})

export const opaSagas = [
  takeLatest(processWeekGroupRows.REQUEST, processWeekGroupRows.saga),
  takeLatest(updateVariables.REQUEST, updateVariables.saga),
  takeLatest(getAfscProperties.REQUEST, getAfscProperties.saga),
  takeLatest(updateAfscProperties.REQUEST, updateAfscProperties.saga),
  takeLatest(addAfscProperties.REQUEST, addAfscProperties.saga),
  takeLatest(deleteAfscProperties.REQUEST, deleteAfscProperties.saga),
  takeLatest(getConditionals.REQUEST, getConditionals.saga),
  takeLatest(getVariables.REQUEST, getVariables.saga),
  takeLatest(getTemplateSections.REQUEST, getTemplateSections.saga),
  takeLatest(getTemplateSection.REQUEST, getTemplateSection.saga),
  takeLatest(updateSectionVariations.REQUEST, updateSectionVariations.saga),
  takeLatest(deleteVariation.REQUEST, deleteVariation.saga),
  takeLatest(deleteWeekGroup.REQUEST, deleteWeekGroup.saga),
  takeLatest(getWeekGroupRows.REQUEST, getWeekGroupRows.saga),
  takeLatest(updateWeekGroupRow.REQUEST, updateWeekGroupRow.saga),
  takeLatest(deleteWeekGroupRow.REQUEST, deleteWeekGroupRow.saga),
]

// eslint-disable-next-line complexity
export default function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case getAfscProperties.SUCCESS:
    case updateAfscProperties.SUCCESS:
    case deleteAfscProperties.SUCCESS:
    case addAfscProperties.SUCCESS: {
      const { afscList = [], deleteWarning } = action.payload
      return state.merge({ afscList: afscList || [], deleteWarning })
    }
    case getConditionals.SUCCESS: {
      const { conditionals = [] } = action.payload
      return state.merge({ conditionals })
    }
    case updateVariables.SUCCESS:
    case getVariables.SUCCESS: {
      const { variables = [] } = action.payload
      variables.sort((a, b) => {
        if (a.display < b.display) {
          return -1
        }
        if (a.display > b.display) {
          return 1
        }
        return 0
      })
      return state.merge({ variables })
    }
    case getTemplateSections.SUCCESS:
    case getTemplateSection.SUCCESS:
    case updateSectionVariations.SUCCESS:
    case deleteVariation.SUCCESS: {
      const { templateSections = [] } = action.payload
      return state.merge({ templateSections })
    }
    case getWeekGroupRows.SUCCESS:
    case updateWeekGroupRow.SUCCESS:
    case deleteWeekGroupRow.SUCCESS: {
      const { weekGroupRows = [] } = action.payload
      return state.merge({ weekGroupRows })
    }
    case deleteWeekGroup.SUCCESS: {
      const { weekGroups = [] } = action.payload
      return state.merge({ weekGroups })
    }
    case processWeekGroupRows.SUCCESS:
    default:
      return state
  }
}
