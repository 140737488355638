export const insertPound = string => {
  const splitString = string.split('')

  for (let index = 0; index < string.length; index++) {
    if (index < 5) {
      splitString[index] = '#'
    }
  }
  string = splitString.join('')
  return string
}

export const processFirstAndSecondIput = (inputIndex, inputRows) => {
  const firstInput = inputRows[inputIndex]
  const secondInput = inputRows[inputIndex + 1] || {}
  const keysToUpdate = ['fieldLabel', 'errorProperty', 'optionSetFunction', 'originalOptions', 'required']
  Object.keys(firstInput).forEach(key => {
    if (keysToUpdate.includes(key)) {
      const newKey = `left${key[0].toUpperCase()}${key.substring(1)}`
      firstInput[newKey] = firstInput[key]
      delete firstInput[key]
    }
  })
  Object.keys(secondInput).forEach(key => {
    if (keysToUpdate.includes(key)) {
      const newKey = `right${key[0].toUpperCase()}${key.substring(1)}`
      secondInput[newKey] = secondInput[key]
      delete secondInput[key]
    }
  })

  return [firstInput, secondInput]
}

export const getOrderConstants = constantsObject => {
  if (constantsObject.data) {
    return constantsObject.data
  }
  return { grades: [], gainBases: [], gainUnits: [] }
}

export const validateFieldValue = (errorProperty, value, inputErrorsCopy) => {
  switch (errorProperty) {
    case 'ssbiInitiatedDate':
      break
    case 'specialOrderNumber':
      inputErrorsCopy[errorProperty] = /^\d+$/u.test(value) ? null : 'must be a number'
      break
    case 'primaryAfsc':
    case 'controlAfsc':
      inputErrorsCopy[errorProperty] = /^[A-Z0-9]{5,6}$/u.test(value) ? null : 'is required and must be 5-6 characters'
      break
    case 'social':
      inputErrorsCopy[errorProperty] = (value && /^\d{9}$/u.test(value.replace(/-/gu, ''))) ? null : 'is required and must be 9 digits'
      break
    default:
      inputErrorsCopy[errorProperty] = (value && value.trim()) ? null : 'is required'
      break
  }

  return inputErrorsCopy
}

export const convertToStandardDateFormat = newDate => {
  const paddedMonth = `0${newDate.getMonth() + 1}`
  const paddedDay = `0${newDate.getDate()}`
  return `${
    (newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : paddedMonth
  }/${
    (newDate.getDate() > 9) ? newDate.getDate() : paddedDay
  }/${
    newDate.getFullYear()
  }`
}

export const isDateValid = date => {
  return date instanceof Date && !isNaN(date)
}
