import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import LoginPage from '../pages/login.page'
import { selectFeatureEnabled } from '../featureToggles/featureToggleDuck'
import { initializeAuth } from '../redux/sagas/auth.sagas'
import { selectInitAuthLoading } from '../redux/selectors/auth.selectors'
import routes from './routes'

function MainNavigator() {
  const dispatch = useDispatch()
  const authInitLoading = useSelector(selectInitAuthLoading)
  const [initialized, setInitialized] = useState(false)

  const shouldShowAfscManagementPage = useSelector(state => selectFeatureEnabled(state, 'opaShowAfscManagementPage'))
  const shouldShowSectionEditingPage = useSelector(state => selectFeatureEnabled(state, 'opaShowSectionEditingPage'))

  useEffect(() => {
    dispatch(initializeAuth.request())
    setInitialized(true)
  }, [dispatch])

  if (!initialized || authInitLoading) {
    return null
  }

  const featureToggledRoutes = routes(shouldShowSectionEditingPage, shouldShowAfscManagementPage)

  return (
    <Switch>
      { featureToggledRoutes.map((route, i) => (
        <Route
          key={ i }
          exact
          path={ route.path }
          render={ props => (
            <route.component { ...props } />
          ) }
        />
      )) }
      <Route key='miss' render={ props => <LoginPage { ...props } /> } />
    </Switch>
  )
}

export default withRouter(MainNavigator)
