import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import 'semantic-ui-css/semantic.min.css'
import { Header, Modal, Button } from 'semantic-ui-react'
import { selectSelectedEvent, selectIsEventModalOpen, TOGGLE_EVENT_MODAL } from './event.duck'
import QRCode from 'qrcode.react'
import ReactToPrint from 'react-to-print'
import { getStandardAmericanDate } from '../../api/date.util'

const DescriptionWrapper = styled.div`
  display: flex;
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing.s}px
`
const InfoLabel = styled.div`
  align-self: flex-end;
  width: 270px;
`

const InfoValue = styled.div`
  width: 100%;
  color: #2185d0;
`

const EventDescriptionWrapper = styled(DescriptionWrapper)`
  flex-direction: column;
  align-items: center;
`

const QRCodeWrapper = styled.div`
  width: 100%;
  display: contents;
`

const PrintTitle = styled.h2`
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba( 34, 36, 38, 0.15);
`

class EventModal extends Component {

  handleCopy = text => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style = { position: 'absolute', left: '-9999px' }
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  handleToggleEventModal = () => {
    const { onToggleEventModal } = this.props
    onToggleEventModal()
  }

  renderInfo = (label, value) => {
    return (
      <InfoContainer>
        <InfoLabel><Header size='small'>{ label }: </Header></InfoLabel>
        <InfoValue onClick={ () => this.handleCopy(value) }>{ value }</InfoValue>
      </InfoContainer>
    )
  }

  getFormattedEventData = event => {
    const startDate = event.startDate ? getStandardAmericanDate(event.startDate) : ''
    const endDate = event.endDate ? getStandardAmericanDate(event.endDate) : ''
    const address1 = event.address1 && event.address1 !== '' ? event.address1 : null
    const address2 = event.address2 && event.address2 !== '' ? event.address2 : null
    const address = address1 ? address1 : address2

    return { startDate, endDate, address }
  }

  render() {
    const { isEventModalOpen, event } = this.props
    if (!event) {
      return null
    }

    const { startDate, endDate, address } = this.getFormattedEventData(event)

    const eventModalRef = React.createRef()

    return (
      <Modal
        open={ isEventModalOpen }
        closeIcon
        onClose={ () => this.handleToggleEventModal() }
        size='small'>
        <Modal.Header>{ event.name }</Modal.Header>
        <Modal.Content>
          { /* On print, everything in this div is printed */ }
          <div ref={ eventModalRef } className='event-qr-modal-print-container'>
            <div className='event-qr-modal-print-content'>
              { /* This title is only visible when printing */ }
              <PrintTitle className='show-on-print'>{ event.name }</PrintTitle>
              <Modal.Description>
                <EventDescriptionWrapper>
                  <div style={ { width: '100%' } }>
                    <Header>INFORMATION</Header>
                    { event.eventDesc && this.renderInfo('Description', event.eventDesc) }
                    { event.eventTypeNm && this.renderInfo('Event Type', event.eventTypeNm) }
                    { address && this.renderInfo('Address', address) }
                    { event.locationName && this.renderInfo('Location', event.locationName) }
                    { event.zipCode && this.renderInfo('Zip Code', event.zipCode) }
                    { startDate && this.renderInfo('Start Date', startDate) }
                    { endDate && this.renderInfo('End Date', endDate) }
                  </div>
                  <QRCodeWrapper>
                    { event?.id && <QRCode value={ event.id.toString() } size={ 500 } style={ { padding: 50 } } /> }
                  </QRCodeWrapper>
                </EventDescriptionWrapper>
              </Modal.Description>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <ReactToPrint
            trigger={ () => <Button>Print</Button> }
            content={ () => eventModalRef.current }
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

EventModal.propTypes = {
  event: PropTypes.object,
  isEventModalOpen: PropTypes.bool,
  onToggleEventModal: PropTypes.func,
}

const mapStateToProps = state => {
  return {
    event: selectSelectedEvent(state),
    isEventModalOpen: selectIsEventModalOpen(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    onToggleEventModal: () => dispatch({ type: TOGGLE_EVENT_MODAL }),
  }
}

export default connect(mapStateToProps, mapActionsToProps)(EventModal)
