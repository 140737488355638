/* eslint-disable max-len */

import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import SvgAirForce from '../icons/AirForce'
import SvgLoader from '../icons/SvgLoader'
import { LightPalette } from '../theme'
import spacing from '../theme/spacing'

export const FormSectionWrapper = styled.div`
  padding-left: ${props => props.theme.spacing.m}px;
  padding-right: ${props => props.theme.spacing.m}px;
  padding-bottom: ${props => props.theme.spacing.s}px;
  border-radius: ${spacing.s}px;
`

export const FormSectionContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}))

export const FormTitle = styled.div(props => ({
  textTransform: 'uppercase',
  color: props.theme.colors.white.default,
  fontSize: props.theme.typography.fontSizes.xl,
  fontWeight: props.theme.typography.fontWeights.bold,
  marginBottom: props.theme.spacing.m,
  fontFamily: 'stratum2-regular',
}))

export const FormSectionTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const FormHeading = styled.div(props => ({
  textTransform: 'uppercase',
  color: props.theme.colors.white.default,
  fontSize: props.theme.typography.fontSizes.xxl,
  lineHeight: 1.2,
  fontWeight: props.theme.typography.fontWeights.bold,
  marginBottom: props.theme.spacing.xs,
  fontFamily: 'stratum2-regular',
}))

export const FormSectionTitle = styled(FormTitle)(props => ({
  paddingLeft: props.theme.spacing.s,
  paddingRight: props.theme.spacing.s,
  marginBottom: props.theme.spacing.s,
  fontSize: props.theme.typography.fontSizes[props.fontSize || 'xl'],
  whiteSpace: 'nowrap',
}))

export const FormAccordionTitle = styled.div(props => ({
  textTransform: 'uppercase',
  color: props.theme.colors.white.default,
  fontSize: props.theme.typography.fontSizes.m,
  fontWeight: props.theme.typography.fontWeights.bold,
  marginBottom: props.theme.spacing.m,
  fontFamily: 'stratum2-regular',
}))

export const FormAccordionHeader = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingLeft: props.theme.spacing.s,
  paddingRight: props.theme.spacing.s,
  width: '100%',
}))

export const FormDescription = styled.div(props => ({
  fontSize: props.theme.typography.fontSizes.m,
  color: props.theme.text.contrast,
  fontFamily: '"Open Sans", sans-serif',
  paddingBottom: props.theme.spacing.m,
}))

export const FormSectionDescription = styled(FormDescription)(props => ({
  paddingLeft: props.theme.spacing.s,
  paddingRight: props.theme.spacing.s,
  paddingBottom: props.theme.spacing.s,
}))

export const FormItemBreak = styled.div`
  flex: 1 0 100%;
  height: 0;
`

export const FormLink = styled.a`
  color: ${props => props.theme.colors.blue.default};
  text-decoration: underline
`

export const PaddedFormItemBreak = styled(FormItemBreak)(props => ({
  paddingTop: props.theme.spacing.s,
}))

export const FormItem = styled.div`
  flex: 1 0 ${props => (props.width && props.width.base) || '50%'};
  min-width: ${props => (props.width && props.width.min) || '150px'};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    width: 2px;
    height: 100%;
  }
`

export const FieldInputContainer = styled.div`
  width: 100%;
  border-radius: ${spacing.xs}px;
  padding: ${props => props.theme.spacing.s}px;
  resize: none;

  input::-webkit-date-and-time-value {
    text-align: left;
  }
  input::-webkit-calendar-picker-indicator {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%2399A4AE" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
  textarea,
  input[type="text"],
  input[type="date"],
  input[type="password"],
  > select,
  > * > * select,
  > * > * > * > select {
    appearance: none;
    -webkit-appearance:none;
    -moz-appearance:none;
    width: 100%;
    border: none;
    resize: none;
    background: ${props => props.theme.colors.input.background};
    border-radius: ${spacing.xs}px;
    color: ${props => (props.disabled ? props.theme.colors.input.disabled : props.theme.colors.input.default)};
    font-weight: ${props => props.theme.typography.fontWeights.regular};
    font-size: ${props => props.theme.typography.fontSizes.m}px;
    box-sizing: border-box;
    padding: ${props => props.theme.spacing.s}px;
    margin: 0;
    min-height: 2.75em;

    ${({ theme }) => {
  if (theme.id === LightPalette.id) {
    return `border: 2px solid ${theme.background.level1};`
  }
}}

    option {
      color: black;
    }

    &::placeholder {
      color: ${props => props.theme.colors.input.placeholder} !important;
    }
  }

  textarea {
    min-height: 7em;
  }
`

export const Link = styled.a(props => ({
  padding: `${props.theme.spacing.m}px`,
  textDecoration: 'underline',
  color: `${props.theme.text.default}`,
}))

export const LargeFieldLabel = styled.label`
  font-size: ${props => props.theme.typography.fontSizes[props.fontSize || 'm']}px;
  font-weight: ${props => props.theme.typography.fontWeights[props.fontWeight || 'regular']};
  color: ${props => (props.hasError ? LightPalette.text.negative : LightPalette.text.default)};
  display: block;
  text-transform: uppercase;
  padding: ${props => props.theme.spacing.s}px;
  padding-bottom: 0;
`

export const OpaFieldLabel = styled.label`
  font-size: ${props => props.theme.typography.fontSizes[props.fontSize || 'm']}px;
  font-weight: bold;
  color: ${props => (props.hasError ? LightPalette.text.negative : LightPalette.text.default)};
  display: block;
  padding: ${props => props.theme.spacing.s}px;
  padding-left: 0;
`

export const FormSubmitButtonsContainer = styled.div(props => ({
  margin: `${props.theme.spacing.m}px`,
  padding: `${props.theme.spacing.s}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const LeadSubmitButtonsContainer = styled(FormSubmitButtonsContainer)`
  margin-top: 0;
  padding-top: 0;
`

export const Disclaimer = styled.p`
  font-size: ${props => props.theme.typography.fontSizes.s}px;
  color: ${props => props.theme.colors.page.text};
  text-align: center;
  padding: ${props => props.theme.spacing.m}px;
  margin-bottom: 0;
`
export const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%
`
export const Loader = styled(SvgLoader)`
`
export const Logo = styled(SvgAirForce)`
  margin-top: ${props => props.theme.spacing.m}px;
`

export const ThankYouContainer = styled.div(props => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `${props.theme.spacing.s}px 0`,
  padding: `${props.theme.spacing.m}px`,
}))

export const ProfileLeadFormHeader = styled.div(props => ({
  paddingTop: `${props.theme.spacing.m}px`,
  paddingLeft: `${props.theme.spacing.s}px`,
}))

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: ${props => (props.padding ? `${props.theme.spacing.m}px` : '')};
  width: 100%;
`

export const AimHighDropdown = styled(Dropdown)`
  min-width: auto !important;
  min-height: 2.75em;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  background: ${props => props.theme.colors.input.background};
  border-radius: ${props => props.theme.spacing.xs}px;
  border: none;
  color: ${props => props.theme.colors.input.default};
  font-weight: ${props => props.theme.typography.fontWeights.regular} !important;
  font-size: ${props => props.theme.typography.fontSizes.m}px;
  box-sizing: border-box;
  padding: ${props => props.theme.spacing.s}px !important;
  padding-left: ${props => props.theme.spacing.s}px !important;
  padding-right: ${props => props.theme.spacing.xs}px !important;
  margin: ${props => props.theme.spacing.s}px !important;

  i {
    color: ${props => props.theme.colors.gray.mid} !important;
  }
  .divider.default.text {
    color: ${props => props.theme.colors.input.placeholder} !important;
  }
`

export const VerifyContainer = styled.div`
  display: grid;
  column-gap:  ${props => props.theme.spacing.s}px;
  grid-template-columns: auto 1fr;
  padding-bottom:  ${props => props.theme.spacing.m}px;
`
export const VerifyText = styled(FormDescription)`
  color: ${props => props.theme.colors.white.default};
`

export const FormContainerWrapper = styled(FormSectionContainer)(props => ({
  paddingBottom: props.theme.spacing.s,
  paddingLeft: props.theme.spacing.s,
  paddingRight: props.theme.spacing.s,
}))

export const RequiredInfo = styled.div(props => ({
  fontSize: props.theme.typography.fontSizes.m,
  color: props.theme.text.subtle,
  fontFamily: '"Open Sans", sans-serif',
}))
