import React from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import reducer from './reducers/index'
import rootSaga from './saga/index'
import MainNavigator from './navigation/mainnavigator'
import { ThemeProvider } from '@emotion/react'
import theme from './theme'
import amplitude from 'amplitude-js'
import analyticsConfig from '../src/config/amplitudeAnalytics'

const history = createBrowserHistory()
amplitude.getInstance().init(analyticsConfig.amplitudePointer)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
export default class App extends React.Component {
  constructor(props) {
    super(props)
    const sagaMiddleware = createSagaMiddleware()
    this.state = {
      store: createStore(
        reducer(history),
        compose(
          applyMiddleware(
            sagaMiddleware,
            routerMiddleware(history)
          )
        )
      ),
    }
    sagaMiddleware.run(rootSaga)
  }

  render() {
    return (
      <QueryClientProvider client={ queryClient }>
        <Provider store={ this.state.store }>
          <ConnectedRouter history={ history }>
            <ThemeProvider theme={ theme }>
              <MainNavigator />
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>
      </QueryClientProvider>
    )
  }
}
