import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Item, Image } from 'semantic-ui-react'
import spacing from '../../theme/spacing'

Intro.propTypes = {
  avatarUrl: PropTypes.string,
  palette: PropTypes.object.isRequired,
  rank: PropTypes.string,
  name: PropTypes.string.isRequired,
  isLoadingRecruiterExperienceData: PropTypes.bool,
  isMobile: PropTypes.bool,
}

export const Rank = styled(Item.Meta)`
  font-family: stratum2-regular;
  text-transform: uppercase;
  color: ${props => props.palette.text.contrast} !important;
`
export const Name = styled(Item.Header)`
  font-family: stratum2-bold !important;
  text-transform: uppercase;
  color: ${props => props.palette.text.contrast} !important;
`

function Intro({ avatarUrl, palette, rank, name, isLoadingRecruiterExperienceData, isMobile }) {
  return (
    <Item.Group >
      <Item style={ { marginTop: 0, marginBottom: spacing.m } }>
        <Image>
          { !!avatarUrl && <Item.Image data-testid='Custom Avatar' src={ avatarUrl } circular size='tiny' /> }
          { (!isLoadingRecruiterExperienceData && !avatarUrl) &&
            <Item.Image
              data-testid='DefaultAvatar'
              palette={ palette }
              src={ require('../../assets/recruiter-website-placeholder.jpg') }
              circular
              size='tiny'
            /> }
        </Image>
        <Item.Content verticalAlign='middle' style={ { paddingTop: spacing.s } }>
          { rank &&
            <Rank
              data-testid='rank'
              palette={ palette }
              style={ { display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', paddingTop: '0 ' } } >
              { rank }
            </Rank> }
          { name && <Name data-testid='name' palette={ palette }>{ name }</Name> }
          { /* <Description palette={ palette }>{ description }</Description> */ }
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

export default Intro
