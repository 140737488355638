/*eslint-disable*/
import * as React from "react";
<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" >
  <title>c info</title>
    
  </svg>
function Info(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 16 16" {...props}>
     <g strokeWidth="1" strokeLinejoin="round" strokeLinecap="round">
      <circle cx="8" cy="8" r="7.5" fill="none"></circle>
      <circle cx="8" cy="4" r="1" stroke="none"></circle>
      <line x1="8" y1="12.5" x2="8" y2="7" fill="none"></line>
    </g>
    </svg>
  );
}

export default Info;
