import PropTypes from 'prop-types'
import React from 'react'
import SvgAirForce from '../../../icons/AirForce'
import { PageTitleContainer, PageTitle as PageTitleStyle } from './PageTitle.styles'

const PageTitle = props => {
  return (
    <PageTitleContainer includeMargins={ props.includeMargins }>
      <SvgAirForce data-testid='air-force-logo' color='#415161' size={ 160 } />
      <PageTitleStyle data-testid='site-name'>{ props.siteName }</PageTitleStyle>
      <PageTitleStyle data-testid='site-title'>{ props.title || '' }</PageTitleStyle>
    </PageTitleContainer>
  )
}

PageTitle.propTypes = {
  siteName: PropTypes.string,
  title: PropTypes.string,
  includeMargins: PropTypes.bool,
}

PageTitle.defaultProps = {
  includeMargins: true,
  siteName: 'AIM HIGH',
}

export default PageTitle
