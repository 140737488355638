const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const shortDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat']

export const getStandardAmericanDate = dateString => {
  const dateObject = dateString ? new Date(dateString) : new Date()
  const isoString = dateObject.toISOString()
  return `${isoString.substring(5, 7)}/${isoString.substring(8, 10)}/${isoString.substring(0, 4)}`
}

export const isValidDate = dateString => {
  return !isNaN(new Date(dateString))
}

export const getCurrentDateString = () => {
  const dateString = new Date().toString()
  return dateString.substring(0, dateString.indexOf('(') - 1)
}

export const getDateTimeString = dateString => {
  if (!isValidDate(dateString)) {
    return ''
  }
  const dateObject = new Date(dateString)
  const parsedDate = `${shortDays[dateObject.getDay()]}, ${shortMonths[dateObject.getMonth()]} ${dateObject.getDate()}`
  const parsedTime = `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(dateObject)}`
  return `${parsedDate}, ${parsedTime}`

}

export const getMonthAndYear = dateString => {
  const dateObject = new Date(dateString)
  return `${shortMonths[dateObject.getUTCMonth()]} ${dateObject.getUTCFullYear()}`
}

export const getYear = dateString => {
  return new Date(dateString).getUTCFullYear().toString()
}

export const getTime = dateString => {
  const dateObject = new Date(dateString)
  return dateObject.getTime() + dateObject.getTimezoneOffset() * 60000
}
