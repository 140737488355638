/* eslint-disable max-lines */
import { ThemeProvider } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Button, Dropdown, Grid, Input, Modal, Placeholder } from 'semantic-ui-react'
import HttpService from '../../../api/HttpService'
import config from '../../../config'
import { selectAuthUserToken } from '../../../redux/selectors/auth.selectors'
import { LightPalette } from '../../../theme'
import { ActionButton, CancelButton } from '../../pages/Opa.styles'
import { FullsizeDatepicker } from '../../pages/ProcessWeekGroupPage.styles'
import { ErrorLabel } from '../../pages/UploadWeekGroupPage.styles'
import { useGetWeekGroupRow } from '../../reactQueries/opaQueries'
import {
  ARMS_STATUS,
  getWeekGroupRows,
  MARITAL_STATUSES,
  selectUpdatingWeekGroupRow,
  selectUpdatingWeekGroupRowError,
  SPACE_FORCE_STATUSES,
  updateWeekGroupRow
} from '../../redux/ducks/opa.duck'
import { OpaLabel } from '../Forms/OpaLabel'
import {
  convertToStandardDateFormat,
  getOrderConstants,
  insertPound,
  isDateValid,
  processFirstAndSecondIput,
  validateFieldValue
} from './processAndValidateWeekGroupRow'

const INPUT_TYPES = {
  DROPDOWN: 'dropdown',
  DATE: 'date',
  TEXT: 'text',
  SOCIAL: 'password',
}

const ARMS_STATUSES = Object.values(ARMS_STATUS)

// eslint-disable-next-line max-statements
export const EditWeekGroupRowModal = ({ isOpen, setOpen, weekGroupRow, weekGroupRowList, weekGroupRowProperties, orderConstants }) => {
  const dispatch = useDispatch()

  const token = useSelector(selectAuthUserToken)
  const afreServiceUrl = config.apiUrl
  const isUpdatingWeekGroupRow = useSelector(selectUpdatingWeekGroupRow)
  const updatingWeekGroupRowError = useSelector(selectUpdatingWeekGroupRowError)
  const { grades, gainBases, gainUnits } = getOrderConstants(orderConstants)
  const isNewWeekGroupRow = Object.keys(weekGroupRow).length === 1

  const [updatedWeekGroupRow, setUpdatedWeekGroupRow] = useState(weekGroupRow)
  const [specialOrderNumber, setSpecialOrderNumber] = useState(weekGroupRow.specialOrderNumber)
  const [name, setName] = useState(weekGroupRow.name)
  const [grade, setGrade] = useState(weekGroupRow.grade)
  const [social, setSocial] = useState()
  const [maskedSocial, setMaskedSocial] = useState(weekGroupRow.maskedSocial)
  const [originalFlight, setOriginalFlight] = useState(weekGroupRow.originalFlight)
  const [currentFlight, setCurrentFlight] = useState(weekGroupRow.currentFlight)
  const [squadron, setSquadron] = useState(weekGroupRow.squadron)
  const [primaryAfsc, setPrimaryAfsc] = useState(weekGroupRow.primaryAfsc)
  const [controlAfsc, setControlAfsc] = useState(weekGroupRow.controlAfsc)
  const [classId, setClassId] = useState(weekGroupRow.classId)
  const [scheduledEntryDate, setScheduledEntryDate] = useState(weekGroupRow.scheduledEntryDate)
  const [gainUnit, setGainUnit] = useState(weekGroupRow.gainUnit)
  const [gainBase, setGainBase] = useState(weekGroupRow.gainBase)
  const [proceedNoLaterThanDate, setProceedNoLaterThanDate] = useState(weekGroupRow.proceedNoLaterThanDate)
  const [bmtGraduationDate, setBmtGraduationDate] = useState(weekGroupRow.bmtGraduationDate)
  const [ssbiInitiatedDate, setSsbiInitiatedDate] = useState(weekGroupRow.ssbiInitiatedDate)
  const [maritalStatus, setMaritalStatus] = useState(weekGroupRow.maritalStatus)
  const [isSpaceForce, setIsSpaceForce] = useState(weekGroupRow.isSpaceForce ? 'YES' : 'NO')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [inputRows, setInputRows] = useState([])
  const [inputErrors, setInputErrors] = useState(weekGroupRow.errors || {})
  const [gainBaseOptions, setGainBaseOptions] = useState(gainBases)
  const [gradeOptions, setGradeOptions] = useState(grades)
  const [gainUnitOptions, setGainUnitOptions] = useState(gainUnits)
  const [armsStatusOptions] = useState(ARMS_STATUSES)
  const [armsStatus, setArmsStatus] = useState(weekGroupRow.armsStatus)
  const [isSocialMasked, setIsSocialMasked] = useState(true)

  const addWeekGroupRow = useMutation(async newWeekGroupRow => {
    return await HttpService.post(`${afreServiceUrl}/opa/weekGroups/${weekGroupRow.id}`, newWeekGroupRow, {}, token).next().value
  }, {
    onSuccess: () => {
      setIsSubmitted(true)
      dispatch(getWeekGroupRows.request({ weekGroup: weekGroupRow.id }))
    },
  })

  const { data: fullWeekGroupRow, isLoading: isWeekGroupRowLoading, mutate: getWeekGroupRow } = useGetWeekGroupRow()

  const inputSetFunctions = useMemo(() => {
    return {
      specialOrderNumber: setSpecialOrderNumber,
      name: setName,
      grade: setGrade,
      social: setSocial,
      originalFlight: setOriginalFlight,
      currentFlight: setCurrentFlight,
      squadron: setSquadron,
      primaryAfsc: setPrimaryAfsc,
      controlAfsc: setControlAfsc,
      classId: setClassId,
      scheduledEntryDate: setScheduledEntryDate,
      gainUnit: setGainUnit,
      gainBase: setGainBase,
      proceedNoLaterThanDate: setProceedNoLaterThanDate,
      bmtGraduationDate: setBmtGraduationDate,
      ssbiInitiatedDate: setSsbiInitiatedDate,
      maritalStatus: setMaritalStatus,
      isSpaceForce: setIsSpaceForce,
      armsStatus: setArmsStatus,
    }
  }, [])

  const clearAndCloseModal = useCallback(() => {
    setInputErrors({})
    setInputRows([])
    setIsSubmitted(false)
    setOpen(false)
  }, [setOpen])

  const updateFieldValue = useCallback((errorProperty, updatedValue, inputRowsCopy, inputRowIndex) => {
    inputSetFunctions[errorProperty](updatedValue)
    inputRowsCopy[inputRowIndex].defaultValue = updatedValue
    setUpdatedWeekGroupRow({ ...updatedWeekGroupRow, [errorProperty]: updatedValue })
    return inputRowsCopy
  }, [inputSetFunctions, updatedWeekGroupRow])

  const updateAndValidateField = useCallback((updatedValue, errorProperty, eventData, inputRowIndex) => {
    let inputErrorsCopy = { ...inputErrors }
    let inputRowsCopy = [...inputRows]
    switch (errorProperty) {
      case 'specialOrderNumber':
        if (/^\d*$/iu.test(updatedValue)) {
          updateFieldValue(errorProperty, updatedValue, inputRowsCopy, inputRowIndex)
        } else {
          updatedValue = eventData.target.defaultValue
          eventData.target.value = eventData.target.defaultValue
        }
        inputErrorsCopy = validateFieldValue(errorProperty, updatedValue, inputErrorsCopy)
        break
      case 'ssbiInitiatedDate':
        inputRowsCopy = updateFieldValue(errorProperty, updatedValue, inputRowsCopy, inputRowIndex)
        break
      case 'primaryAfsc':
      case 'controlAfsc':
        if (/^[a-zA-Z0-9]{0,6}$/u.test(updatedValue)) {
          updatedValue = updatedValue.toUpperCase()
          eventData.target.defaultValue = updatedValue
          eventData.target.value = updatedValue
          inputRowsCopy = updateFieldValue(errorProperty, updatedValue, inputRowsCopy, inputRowIndex)
        } else {
          updatedValue = eventData.target.defaultValue
          eventData.target.value = eventData.target.defaultValue
        }
        inputErrorsCopy = validateFieldValue(errorProperty, updatedValue, inputErrorsCopy)
        break
      case 'social':
        if (/^\d{0,9}$/u.test(updatedValue.replace(/-/gu, ''))) {
          updatedValue = updatedValue.toUpperCase()
          eventData.target.defaultValue = updatedValue
          eventData.target.value = updatedValue
          setMaskedSocial(insertPound(updatedValue))
          inputRowsCopy = updateFieldValue(errorProperty, updatedValue, inputRowsCopy, inputRowIndex)
        } else {
          updatedValue = eventData.target.defaultValue
          eventData.target.value = eventData.target.defaultValue
        }
        inputErrorsCopy = validateFieldValue(errorProperty, updatedValue, inputErrorsCopy)
        break
      default:
        inputRowsCopy = updateFieldValue(errorProperty, updatedValue, inputRowsCopy, inputRowIndex)
        inputErrorsCopy = validateFieldValue(errorProperty, updatedValue, inputErrorsCopy)
        break
    }

    setInputRows(inputRowsCopy)
    setInputErrors(inputErrorsCopy)
  }, [inputErrors, inputRows, updateFieldValue])

  const renderSocialInput = (errorProperty, inputRowIndex, generalProps) => {
    return isWeekGroupRowLoading ?
      (
        <Placeholder>
          <Placeholder.Line />
        </Placeholder>
      ) : (
        <Input
          fluid
          key={ isSocialMasked ? 'masked' : 'unmasked' }
          data-testid={ `${errorProperty}-display` }
          onChange={ (eventData, event) => updateAndValidateField(event.value, errorProperty, eventData, inputRowIndex) }
          action={
            <Button
              disabled={ (social || social === '') && social.length === 0 }
              icon={ isSocialMasked ? 'eye slash' : 'eye' }
              onClick={ () => {
                if (social && social.length > 0) {
                  setIsSocialMasked(!isSocialMasked)
                }
              } }
            />
          }
          { ...generalProps }
        />
      )
  }

  const renderInputType = (errorProperty, inputRowIndex, optionSetFunction, originalOptions, generalProps) => {
    if (generalProps.type === INPUT_TYPES.DROPDOWN) {
      return (
        <Dropdown
          fluid
          search
          selection
          data-testid={ `${errorProperty}-dropdown` }
          onChange={ (eventData, event) => updateAndValidateField(event.value, errorProperty, eventData, inputRowIndex) }
          onAddItem={ (eventData, event) => {
            optionSetFunction([event.value, ...originalOptions])
            updateAndValidateField(event.value, errorProperty, eventData, inputRowIndex)
          }
          }
          { ...generalProps }
        />
      )
    } else if (generalProps.type === INPUT_TYPES.DATE) {
      const dateValue = new Date(generalProps.defaultValue)
      return (
        <FullsizeDatepicker>
          <SemanticDatepicker
            data-testid={ `${errorProperty}-datepicker` }
            onChange={
              (eventData, event) => updateAndValidateField(convertToStandardDateFormat(event.value), errorProperty, eventData, inputRowIndex)
            }
            format='MM/DD/YYYY'
            value={ isDateValid(dateValue) ? dateValue : undefined }
            clearable={ false }
            pointing='top left'
            datePickerOnly
            { ...generalProps }
          />
        </FullsizeDatepicker>
      )
    } else if (errorProperty === 'social') {
      generalProps.type = isSocialMasked ? INPUT_TYPES.SOCIAL : INPUT_TYPES.TEXT
      return renderSocialInput(errorProperty, inputRowIndex, generalProps)
    } else {
      return (
        <Input
          fluid
          data-testid={ `${errorProperty}-display` }
          onChange={ (eventData, event) => updateAndValidateField(event.value, errorProperty, eventData, inputRowIndex) }
          { ...generalProps }
        />
      )
    }
  }

  const renderInputRow = (
    { leftFieldLabel, leftErrorProperty, leftOptionSetFunction, leftOriginalOptions, leftRequired, ...leftGerneralProps },
    { rightFieldLabel, rightErrorProperty, rightOptionSetFunction, rightOriginalOptions, rightRequired, ...rightGeneralProps },
    inputRowIndex
  ) => {
    return (
      <Grid.Row columns='equal' key={ leftErrorProperty } style={ { paddingTop: 0 } }>
        <Grid.Column>
          <OpaLabel label={ leftFieldLabel } error={ inputErrors[leftErrorProperty] } required={ leftRequired } />
          { renderInputType(leftErrorProperty, inputRowIndex, leftOptionSetFunction, leftOriginalOptions, leftGerneralProps) }
        </Grid.Column>
        <Grid.Column>
          { rightFieldLabel &&
            <>
              <OpaLabel label={ rightFieldLabel } error={ inputErrors[rightErrorProperty] } required={ rightRequired } />
              { renderInputType(rightErrorProperty, inputRowIndex + 1, rightOptionSetFunction, rightOriginalOptions, rightGeneralProps) }
            </>
          }
        </Grid.Column>
      </Grid.Row>
    )
  }

  const validateAndSubmitForm = () => {
    let inputErrorsCopy = {}
    inputRows.forEach(inputRow => {
      const { leftErrorProperty, rightErrorProperty, defaultValue } = inputRow
      inputErrorsCopy = validateFieldValue(
        leftErrorProperty || rightErrorProperty,
        defaultValue,
        inputErrorsCopy
      )
    })
    setInputErrors(inputErrorsCopy)

    if (!Object.values(inputErrorsCopy).find(inputError => !!inputError)) {
      const finalWeekGroupRow = {
        specialOrderNumber,
        name,
        grade,
        social,
        maskedSocial,
        originalFlight,
        currentFlight,
        squadron,
        primaryAfsc,
        controlAfsc,
        classId,
        scheduledEntryDate,
        gainUnit,
        gainBase,
        proceedNoLaterThanDate,
        bmtGraduationDate,
        ssbiInitiatedDate,
        maritalStatus,
        isSpaceForce: isSpaceForce === 'YES',
        armsStatus,
      }

      if (isNewWeekGroupRow) {
        addWeekGroupRow.mutate(finalWeekGroupRow)
      } else {
        dispatch(updateWeekGroupRow.request({
          weekGroupId: weekGroupRow.id,
          sortId: weekGroupRow.sort,
          updatedWeekGroupRowData: finalWeekGroupRow,
          weekGroupRowList,
          weekGroupRowIndex: weekGroupRowList.indexOf(weekGroupRow),
        }))

        setIsSubmitted(true)
      }
    }
  }

  useEffect(() => {
    if (updatingWeekGroupRowError && updatingWeekGroupRowError.data) {
      setInputErrors({ ...updatingWeekGroupRowError.data, type: null, error: null })
    }
  }, [updatingWeekGroupRowError])

  useEffect(() => {
    const updateError = updatingWeekGroupRowError && updatingWeekGroupRowError.data && updatingWeekGroupRowError.data.error
    const validForm = !Object.values(inputErrors).find(inputError => !!inputError)
    if (!isUpdatingWeekGroupRow && !addWeekGroupRow.isLoading && !updateError && !addWeekGroupRow.isError && validForm && isSubmitted) {
      clearAndCloseModal()
    }
  }, [isUpdatingWeekGroupRow, addWeekGroupRow, updatingWeekGroupRowError, isSubmitted, clearAndCloseModal, inputErrors])

  const getArmsStatusOptions = useCallback(() => {
    let statusOptions = armsStatusOptions
    const currentArmsStatus = updatedWeekGroupRow.armsStatus
    if (currentArmsStatus === ARMS_STATUS.NOT_READY || !currentArmsStatus) {
      statusOptions = armsStatusOptions.filter(option => option === ARMS_STATUS.READY || option === ARMS_STATUS.NOT_READY)
    } else if (currentArmsStatus === ARMS_STATUS.READY) {
      statusOptions = armsStatusOptions.filter(option => option === ARMS_STATUS.READY)
    } else if (currentArmsStatus === ARMS_STATUS.FAILED) {
      statusOptions = armsStatusOptions.filter(option => option === ARMS_STATUS.COMPLETE || option === ARMS_STATUS.FAILED)
    } else if (currentArmsStatus === ARMS_STATUS.COMPLETE) {
      statusOptions = armsStatusOptions.filter(option => option === ARMS_STATUS.COMPLETE)
    }
    return { currentArmsStatus, statusOptions }
  }, [armsStatusOptions, updatedWeekGroupRow])

  useEffect(() => {
    if (!(fullWeekGroupRow && fullWeekGroupRow.data) && !isNewWeekGroupRow) {
      getWeekGroupRow({ weekGroupId: weekGroupRow.id, dataSortValue: weekGroupRow.sort, fullOrderData: true })
    }
  }, [fullWeekGroupRow, getWeekGroupRow, weekGroupRow, isNewWeekGroupRow])

  useEffect(() => {
    if (fullWeekGroupRow && fullWeekGroupRow.data && fullWeekGroupRow.data.data && social === undefined) {
      setSocial(fullWeekGroupRow.data.data.social)
      setUpdatedWeekGroupRow({ ...updatedWeekGroupRow, social: fullWeekGroupRow.data.data.social })
    }
  }, [fullWeekGroupRow, updatedWeekGroupRow, social])

  useEffect(() => {
    const propertiesHiddenWhenNewRow = ['specialOrderNumber', 'armsStatus']
    const finalWeekGroupRowProperties = isNewWeekGroupRow ?
      weekGroupRowProperties.filter(weekGroupRowProp => !propertiesHiddenWhenNewRow.includes(weekGroupRowProp.value)) : weekGroupRowProperties
    setInputRows(finalWeekGroupRowProperties.map(prop => {
      const inputProperties = {
        fieldLabel: prop.text,
        defaultValue: updatedWeekGroupRow[prop.value],
        placeholder: `Enter ${prop.text}`,
        errorProperty: prop.value,
        type: INPUT_TYPES.TEXT,
        required: true,
      }
      let finalProperties = {}
      switch (prop.value) {
        case 'maskedSocial':
          finalProperties = {
            ...inputProperties,
            defaultValue: updatedWeekGroupRow.social,
            errorProperty: 'social',
            type: INPUT_TYPES.SOCIAL,
          }
          break
        case 'grade':
          finalProperties = {
            ...inputProperties,
            allowAdditions: true,
            optionSetFunction: setGradeOptions,
            originalOptions: gradeOptions,
            type: INPUT_TYPES.DROPDOWN,
            options: gradeOptions.map(airmenGrade => ({
              key: airmenGrade,
              value: airmenGrade,
              text: airmenGrade,
            })),
          }
          break
        case 'gainBase':
          finalProperties = {
            ...inputProperties,
            allowAdditions: true,
            optionSetFunction: setGainBaseOptions,
            originalOptions: gainBaseOptions,
            type: INPUT_TYPES.DROPDOWN,
            options: gainBaseOptions.map(base => ({
              key: base,
              text: base,
              value: base,
            })),
          }
          break
        case 'gainUnit':
          finalProperties = {
            ...inputProperties,
            allowAdditions: true,
            optionSetFunction: setGainUnitOptions,
            originalOptions: gainUnitOptions,
            type: INPUT_TYPES.DROPDOWN,
            options: gainUnitOptions.map(unit => ({
              key: unit,
              text: unit,
              value: unit,
            })),
          }
          break
        case 'ssbiInitiatedDate':
          finalProperties = {
            ...inputProperties,
            type: INPUT_TYPES.DATE,
            required: false,
          }
          break
        case 'bmtGraduationDate':
        case 'proceedNoLaterThanDate':
        case 'scheduledEntryDate':
          finalProperties = {
            ...inputProperties,
            type: INPUT_TYPES.DATE,
          }
          break
        case 'maritalStatus':
          finalProperties = {
            ...inputProperties,
            type: INPUT_TYPES.DROPDOWN,
            options: MARITAL_STATUSES.map(status => ({
              key: status,
              value: status,
              text: status,
            })),
          }
          break
        case 'isSpaceForce':
          finalProperties = {
            ...inputProperties,
            defaultValue: updatedWeekGroupRow.isSpaceForce ? 'YES' : 'NO',
            type: INPUT_TYPES.DROPDOWN,
            options: SPACE_FORCE_STATUSES.map(spaceForceStatus => ({
              key: spaceForceStatus,
              value: spaceForceStatus,
              text: spaceForceStatus,
            })),
          }
          break
        case 'armsStatus': {
          const { currentArmsStatus, statusOptions } = getArmsStatusOptions()

          finalProperties = {
            ...inputProperties,
            defaultValue: currentArmsStatus,
            type: INPUT_TYPES.DROPDOWN,
            options: statusOptions.map(armslcStatus => ({
              key: armslcStatus,
              value: armslcStatus,
              text: armslcStatus,
            })),
          }
          break
        }
        default:
          finalProperties = { ...inputProperties }
          break
      }
      return finalProperties
    }))
  }, [
    weekGroupRow,
    weekGroupRowProperties,
    gainBaseOptions,
    gainUnitOptions,
    gradeOptions,
    updatedWeekGroupRow,
    getArmsStatusOptions,
    isNewWeekGroupRow,
  ])

  return (
    <Modal
      open={ isOpen }>
      <ThemeProvider theme={ LightPalette }>
        <Modal.Header data-testid='modal-header'>Update Week Group Row Data</Modal.Header>
        <Modal.Content style={ { height: '750px', overflowY: 'scroll' } }>
          <Modal.Description data-testid='modal-description'>
            <Grid>
              {
                inputRows.map((_inputRow, inputIndex) => {
                  if (inputIndex % 2 === 0) {
                    const [firstInput, secondInput] = processFirstAndSecondIput(inputIndex, inputRows)
                    return renderInputRow(firstInput, secondInput, inputIndex)
                  }
                  return (<div key={ inputIndex } />)
                })
              }
              { orderConstants.isError && <ErrorLabel>Failed to retrieve order constansts. Please try refreshing the page.</ErrorLabel> }
              { updatingWeekGroupRowError && updatingWeekGroupRowError.data && updatingWeekGroupRowError.data.error &&
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <ErrorLabel data-testid='update-week-group-row-general-error-text'>
                      { updatingWeekGroupRowError.data.error.toUpperCase() }
                    </ErrorLabel>
                  </Grid.Column>
                </Grid.Row>
              }
              { addWeekGroupRow.isError &&
                <Grid.Row columns='equal'>
                  <Grid.Column textAlign='center'>
                    <ErrorLabel data-testid='update-week-group-row-general-error-text'>
                      { addWeekGroupRow.error.response.data.error.toUpperCase() }
                    </ErrorLabel>
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <CancelButton data-testid='modal-cancel-button' onClick={ () => clearAndCloseModal() }>
            Cancel
          </CancelButton>
          {
            (isUpdatingWeekGroupRow || addWeekGroupRow.isLoading) ?
              <ActionButton loading>Loading</ActionButton> :
              <ActionButton
                content={ isNewWeekGroupRow ? 'Create' : 'Update' }
                icon='checkmark'
                data-testid='modal-action-button'
                onClick={ () => validateAndSubmitForm() }
              />
          }
        </Modal.Actions>
      </ThemeProvider>
    </Modal>
  )
}

EditWeekGroupRowModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  weekGroupRow: PropTypes.object,
  weekGroupRowList: PropTypes.array,
  weekGroupRowProperties: PropTypes.array,
  orderConstants: PropTypes.object,
}
