import styled from '@emotion/styled'
import { Divider as SemanticDivider } from 'semantic-ui-react'
import { DarkPalette } from '../../theme'
import spacing from '../../theme/spacing'
import { fontSizes } from '../../theme/typography'
import { Footer } from '../share/share.page.styles'

export const ProfileCard = styled.div`
  color: ${props => props.theme.text.default};
  font-family: 'Open Sans', sans-serif;
`

export const ProfileFooter = styled(Footer)`
  margin-top: ${spacing.m}px;
`

export const ProfileFormWrapper = styled.div`
  background-color: ${DarkPalette.background.level1};
  border-radius: ${spacing.m}px;
  min-width: 252px;
  max-width: 1024px;
`

export const ProfileSectionTitle = styled.div`
  color: ${props => props.theme.text.contrast};
  font-size: ${fontSizes.m}px;
  font-family: stratum2-bold;
  text-transform: uppercase;
  padding-bottom: ${spacing.s}px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
`

export const LeadDivider = styled(SemanticDivider)`
  margin-top: 0px !important;
  width: 100%;
  border-color: ${props => props.theme.text.contrast};
`
